import { logEvent } from './log-event';

// Tool Usage
export const event16 = (e) => {
  const label = document.querySelector('[data-analytics-event16]');

  if (!label) return;

  const text = document.querySelector('[data-toolname]').dataset.toolname;

  if (!text) return;

  const analyticsEvent = {};
  analyticsEvent.eventInfo = {
    type: window.ctConstants.trackEvent,
    eventAction: window.ctConstants.virtualagent,
    eventLabel: text,
    eventValue: 1,
  };
  analyticsEvent.category = {
    primaryCategory: window.ctConstants.custom,
  };
  analyticsEvent.subcategory = 'Interest';

  window.digitalData.event.push(analyticsEvent);

  logEvent('Tool Usage', analyticsEvent);
};
