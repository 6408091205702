(() => {
  const ctaWrapper = document.querySelector('.c-back-to-top-cta');
  if (ctaWrapper) {
    window.addEventListener('scroll', () => {
      window.pageYOffset > 250
        ? ctaWrapper.classList.add('is-visible')
        : ctaWrapper.classList.remove('is-visible');
    });
    const cta = document.querySelector('.js-back-to-top-cta__link');
    cta.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  }
})();
