/* eslint-disable no-console */

// use data attribute selectors for each type of event tracking (coresponding to the Adobe name)
// e.g. querySelector('[data-analytics-event2]') for 'Link Click'

import { event2 } from './event2';
import { event3 } from './event3';
import { event4 } from './event4';
import { event48 } from './event48';
import { event22 } from './event22';
import { event23 } from './event23';

import { event24 } from './event24';
import { event25 } from './event25';
import { event46 } from './event46';
import { event47 } from './event47';
import { event49 } from './event49';

const linkClickEvents = [
  event2,
  event3,
  event4,
  event48,
  event22,
  event23,
  event46,
  event47,
  event49,
  event24,
  event25,
];

const changeEvents = [event46];

const bindClinkEvents = () => {
  linkClickEvents.forEach((eventFn) => {
    document.addEventListener('click', eventFn);
  });
};

const bindChangeEvents = () => {
  changeEvents.forEach((eventFn) => {
    document.querySelector('[data-analytics-change]')
      ? document.querySelector('[data-analytics-change]').addEventListener('change', eventFn)
      : '';
  });
};

const waitForAnalytics = (cb) => {
  let analyticsInterval;
  const analyticsTimeout = setTimeout(() => {
    console.warn('Analytics timeout');
    clearInterval(analyticsInterval);
  }, 8000);
  analyticsInterval = setInterval(() => {
    if (window.ctConstants) {
      clearInterval(analyticsInterval);
      clearTimeout(analyticsTimeout);
      cb();
      // event16();
    }
  }, 100);
};

const init = (analyticsUrl) => {
  let analyticsTriggered = false;
  let analyticsTimeout = null;
  function loadAnalyticsOnInteraction() {
    if (analyticsTriggered) return;
    if (analyticsTimeout) clearTimeout(analyticsTimeout);
    analyticsTriggered = true;
    if (analyticsUrl) {
      const analyticsLaunchScript = document.createElement('script');
      analyticsLaunchScript.type = 'text/javascript';
      analyticsLaunchScript.src = analyticsUrl;
      document.getElementsByTagName('head')[0].appendChild(analyticsLaunchScript);
    }
    // remove interaction listeners
    window.removeEventListener('scroll', loadAnalyticsOnInteraction);
    window.removeEventListener('wheel', loadAnalyticsOnInteraction);
    document.removeEventListener('touchstart', loadAnalyticsOnInteraction);
    document.removeEventListener('mouseenter', loadAnalyticsOnInteraction);
    document.removeEventListener('focusin', loadAnalyticsOnInteraction);
  }
  // add timed load
  analyticsTimeout = setTimeout(loadAnalyticsOnInteraction, 7000);
  // add interaction listeners
  window.addEventListener('scroll', loadAnalyticsOnInteraction, { passive: true });
  window.addEventListener('wheel', loadAnalyticsOnInteraction, { passive: true });
  document.addEventListener('touchstart', loadAnalyticsOnInteraction, { passive: true });
  document.addEventListener('mouseenter', loadAnalyticsOnInteraction);
  document.addEventListener('focusin', loadAnalyticsOnInteraction);
};

// init analytics
init(window.analyticsUrl);

// bind all the click events
waitForAnalytics(bindClinkEvents);
waitForAnalytics(bindChangeEvents);
// bind all before route change events
