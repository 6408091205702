import { logEvent } from './log-event';

/*eslint-disable */
// Diagnostic tool Completion
export const event25 = (e) => {
  if (e.target.tagName === 'IMG') return;

  if (
    !e.target.parentElement.classList.contains('swiper-button-next-btn') &&
    !e.target.parentElement.hasAttribute('data-analytics-event25')
  ) {
    return;
  }

  let totalSlide;
  if (
    e.target.parentElement.classList.contains('swiper-button-next-btn') ||
    e.target.parentElement.hasAttribute('data-analytics-event25')
  ) {
    const nodes = Array.prototype.slice.call(
      document.querySelectorAll('.swiper-wrapper .article__quiz')
    );

    totalSlide = nodes.length;
    const currentSlide = nodes.indexOf(document.querySelector('.swiper-slide-active'));
    if (currentSlide !== -1) {
      return;
    }
  }

  const total = totalSlide;

  const analyticsEvent = {};

  window.digitalData.component[0].attributes.position = `${total} of ${total}`;
  window.digitalData.component[0].attributes.subattributes = [];
  window.digitalData.component[0].attributes.subattributes.push({
    DTAns: 'text',
    DTQnsID: 'qnsID',
    DTAnsID: 'ansID',
  });

  analyticsEvent.eventInfo = {
    type: window.ctConstants.diagtooleve,
  };
  analyticsEvent.category = {
    primaryCategory: window.ctConstants.engagement,
  };
  analyticsEvent.subcategory = 'Interest';
  analyticsEvent.action = window.ctConstants.DiagnosticToolResults;
  analyticsEvent.label = 'result';

  window.digitalData.event.push(analyticsEvent);

  logEvent('Diagnositic Tool Completion', analyticsEvent);
};
