/* eslint-disable */
window.readArticles = () => {
  const articles = JSON.parse(window.storage().getStorage('readarticles'));
  const articleBlock = [].slice.call(document.querySelectorAll('.articleBlock'));
  const challengeBlock = [].slice.call(document.querySelectorAll('.c-challenge'));
  if (articleBlock.length) {
    articleBlock.forEach((article) => {
      if (articles && articles.length && articles.indexOf(article.getAttribute('data-id')) !== -1) {
        article.classList.add('read');
      }
    });
  }
  if (challengeBlock.length) {
    challengeBlock.forEach((challenge) => {
      if (
        articles &&
        articles.length &&
        articles.indexOf(challenge.getAttribute('data-id')) !== -1
      ) {
        challenge.classList.add('read');
      }
    });
  }
};
(() => {
  window.readArticles();
})();
