import Swiper from 'swiper/bundle';
import hcSticky from 'hc-sticky';
import questions from '../../molecules/questions/questions';

/*eslint-disable  */

const articlesListing = {
  breakpoint: window.matchMedia('(min-width: 62em)'),
  layoutType: document.querySelector('input[name="articleLayoutType"]')
    ? document.querySelector('input[name="articleLayoutType"]').value
    : '',
  mySwiper: null,

  showElements: function (show) {
    let contentStatus;
    let titleStatus;
    if (show) {
      contentStatus = 'block';
      titleStatus = 'none';
      if (document.querySelector('.article-detail__pagination')) {
        document.querySelector('.article-detail__pagination').classList.add('hidden');
      }
    } else {
      contentStatus = 'none';
      titleStatus = 'block';
      if (document.querySelector('.article-detail__pagination')) {
        document.querySelector('.article-detail__pagination').classList.remove('hidden');
      }
    }
    if (document.querySelector('.article-detail__heading')) {
      document.querySelector('.article-detail__heading').style.display = titleStatus;
    }
    if (document.querySelector('.article-detail__title')) {
      document.querySelector('.article-detail__title').style.display = contentStatus;
    }
    if (document.querySelector('footer')) {
      document.querySelector('footer').style.display = contentStatus;
    }
    if (document.querySelector('.c-mood-selector-wrapper')) {
      document.querySelector('.c-mood-selector-wrapper').style.display = contentStatus;
    }

    if (!document.querySelector('.article__quiz') && !document.querySelector('.article__personaQuiz') && document.querySelector('.c-social-share')) {
      document.querySelector('.c-social-share').style.display = contentStatus;
    }
  },

  createHeadingElement: function () {
    let headingEl;
    if (document.querySelector('.article-detail__title')) {
      headingEl = document.querySelector('.article-detail__title');
      if (document.querySelector('.article-detail__heading')) {
        document.querySelector('.article-detail__heading').innerHTML = headingEl.innerHTML;
        headingEl.style.display = 'none';
      }
    }
  },

  hideLastSlideNav: function () {
    if (
      document.querySelector('.swiper-button-next-btn.swiper-button-disabled') &&
      document.querySelector('.article-detail__slidernav')
    ) {
      document.querySelector('.article-detail__slidernav').classList.add('hidden');

      // in quiz section call result calulation on last slide
      if (document.querySelector('#quiztype') && typeof questions.submitQuiz !== 'undefined') {
        questions.submitQuiz();
        // this.mySwiper.allowTouchMove = false;
        // this.mySwiper.allowSlidePrev = false;
        if (document.querySelector('.content-instaCard.article-quiz')) {
          document
            .querySelector('.content-instaCard.article-quiz')
            .classList.add('article-quiz-pb-0');
        }
      }
    } else {
      document.querySelector('.article-detail__slidernav').classList.remove('hidden');
    }
  },

  enableSwiper: function () {
    this.mySwiper = new Swiper('.swiper-container', {
      loop: false,
      init: true,
      slidesPerView: 'auto',
      centeredSlides: false,
      direction: 'horizontal',
      autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: false,
      },
      navigation: {
        nextEl: '.swiper-button-next-btn',
        prevEl: '.swiper-button-prev-btn',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      on: {
        init: () => {
          this.showElements(false);
          this.createHeadingElement();
          this.hideLastSlideNav();
        },
        slideChange: () => {
          this.hideLastSlideNav();
        },
      },
    });
  },

  breakpointChecker: function () {
    if (this.layoutType !== undefined && this.layoutType === 'instaCard') {
      if (this.breakpoint.matches === true) {
        if (this.mySwiper && this.mySwiper !== undefined && this.mySwiper.initialized === true) {
          this.showElements(true);
          this.mySwiper.detachEvents();
          this.mySwiper.destroy(true, true);
          return null;
        }
      } else if (this.breakpoint.matches === false) {
        return this.enableSwiper();
      }
    }

    return null;
  },

  setReadflag: function (article) {
    /* eslint-disable*/
    if (window.storage().getStorage('readarticles')) {
      const articles = JSON.parse(window.storage().getStorage('readarticles'));

      if (articles.indexOf(article) === -1) {
        articles.push(article);
        window.storage().setStorage('readarticles', JSON.stringify(articles));
      }
    } else {
      window.storage().setStorage('readarticles', JSON.stringify([article]));
    }
  },
  stickysidebar: function () {
    window.onload = function () {
      let wrapperElement = document.querySelector('.article-detail__wrapper');
      if (wrapperElement) {
        let parentNodeElement = wrapperElement.parentNode;
        if (
          articlesListing.breakpoint.matches === true &&
          wrapperElement &&
          !parentNodeElement.classList.contains('article-quiz')
        ) {
          const scrollHeight = wrapperElement.scrollHeight;
          wrapperElement.style.minHeight = `${scrollHeight}px`;
        }
        return new hcSticky('.article-detail__sidebar.instacard-slide', {
          stickTo: '.article-detail__wrapper',
          responsive: {
            980: {
              disable: true,
            },
          },
        });
      }
    };
  },
  init: function () {
    let articlePage = document.querySelector('.article-wrapper');

    let articleId;
    if (articlePage) {
      articleId = articlePage.getAttribute('data-page-id');
      this.setReadflag(articleId);
    }
    this.breakpoint.addListener(this.breakpointChecker);
    this.breakpointChecker();
  },
};

articlesListing.init();
articlesListing.stickysidebar();
