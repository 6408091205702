/* eslint-disable */
const challengeList = () => {
  const buttons = [].slice.call(document.querySelectorAll('.challenge-btn'));
  if (buttons.length) {
    buttons.forEach((button) => {
      button.addEventListener('click', (event) => {
        const passionID = event.currentTarget.getAttribute('data-label');
        const passion = document.querySelector('#passion_' + passionID);
        const articleType = document.querySelector('#articleType_' + passionID);
        const startIndex = document.querySelector('#startIndex__' + passionID);
        const divToAppend = document.querySelector('.' + passionID + '__list');
        const loadbtn = event.currentTarget;
        let json = '';

        if (Number(startIndex.value) === 1) {
          json = `articletypes=${articleType.value}&passion=${passion.value}&imgwidth=568&imgheight=287&start=${Number(
            startIndex.value
          )}&end=${Number(startIndex.value) + 3}`;
          window.getAjaxData(4, json, divToAppend, 'challenge', true, loadbtn);
          startIndex.value = Number(startIndex.value) + 4;
        } else {
          json = `articletypes=${articleType.value}&passion=${passion.value}&imgwidth=568&imgheight=287&start=${Number(
            startIndex.value
          )}&end=${Number(startIndex.value) + 2}`;
          window.getAjaxData(2, json, divToAppend, 'challenge', false, loadbtn);
          startIndex.value = Number(startIndex.value) + 3;
        }
      });
    });
  }
};
(() => {
  challengeList();
})();
