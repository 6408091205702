import { logEvent } from './log-event';
import { event16 } from './event16';

let Event16fired = false;
const getMatchingElement = (elem, selector) => {
  let matchElement;
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.querySelector(selector)) {
      matchElement = elem.querySelector(selector);
      elem = document;
    }
  }
  return matchElement;
};

// Diagnostic tool progress
export const event24 = (e) => {
  if (e.target.tagName === 'IMG') return;

  const label = e.target.closest('[data-analytics-event24]');

  if (!label) return;

  if (!Event16fired) {
    event16();
    Event16fired = true;
  }

  const quesTextElement = getMatchingElement(e.target, '.c-quiz__title p');

  const parentlabel = e.target.parentNode.parentNode;
  const quesText = quesTextElement.textContent;
  const text = parentlabel.getAttribute('aria-label')
    ? parentlabel.getAttribute('aria-label')
    : null;
  const current = parentlabel.getAttribute('data-current');
  const total = parentlabel.getAttribute('data-total');
  const qnsID = parentlabel.getElementsByTagName('input')[0].name;
  const ansID = parentlabel.getElementsByTagName('input')[0].id;

  if (!text) return;

  const analyticsEvent = {};
  window.digitalData.component = [];
  window.digitalData.component.push({
    attributes: {
      position: `${current}`,
    },
  });
  window.digitalData.component[0].attributes.subattributes = [];
  window.digitalData.component[0].attributes.subattributes.push({
    DTQns: quesText,
    DTAns: text,
    DTQnsID: qnsID,
    DTAnsID: ansID,
  });

  analyticsEvent.eventInfo = {
    type: window.ctConstants.diagtooleve,
  };

  analyticsEvent.category = {
    primaryCategory: window.ctConstants.engagement,
  };
  analyticsEvent.subcategory = 'Interest';
  analyticsEvent.action = window.ctConstants.diagtoolcta;
  analyticsEvent.label = `${current} of ${total}`;

  window.digitalData.event.push(analyticsEvent);

  logEvent('Diagnostic tool progress', analyticsEvent);
};
