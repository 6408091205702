import { logEvent } from './log-event';

// Clicks to Filter
export const event46 = (e) => {
  const filter = e.target.closest('[data-analytics-event46]');

  if (!filter) return;

  const text = filter.value;

  const analyticsEvent = {};
  analyticsEvent.eventInfo = {
    type: window.ctConstants.trackEvent,
    eventAction: window.ctConstants.filter,
    eventLabel: `${text}`,
    eventValue: 1,
  };
  analyticsEvent.category = {
    primaryCategory: window.ctConstants.custom,
  };
  analyticsEvent.subcategory = 'Interest';

  window.digitalData.event.push(analyticsEvent);

  logEvent('Click to Filter', analyticsEvent);
};
