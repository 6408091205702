window.isElementVisible = (id) => {
  window.ObserveImages = (elem) => {
    // eslint-disable-next-line
    const imageObserver = new IntersectionObserver((entries, imgObserver) => {
      const ctaBtnSticky = document.getElementsByClassName('tile-cta')[0];
      if (window.innerWidth <= 991) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            ctaBtnSticky.classList.remove('sticky-tile-cta');
            return;
          }
          if (window.innerWidth < 992) {
            ctaBtnSticky.classList.add('sticky-tile-cta');
          }
        });
      }
    });

    elem.forEach((val) => {
      imageObserver.observe(val);
    });
  };
  const elements = [].slice.call(document.querySelectorAll(id));

  window.ObserveImages(elements);
};

window.onScrollOnboarding = (id) => {
  document.addEventListener('scroll', (event) => {
    window.isElementVisible(id);
  });
};

window.onClickFindMoreOnboard = () => {
  const cta = document.querySelector('.btn-find-more');
  cta.addEventListener('click', () => {
    const heightToScroll =
      document.querySelector('#onboarding').getBoundingClientRect().top + window.scrollY;
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo({
        top: heightToScroll,
        behavior: 'smooth',
      });
    } else {
      window.scroll(0, heightToScroll);
    }
  });
};
