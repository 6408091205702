/* eslint-disable func-names */
/* eslint-disable no-console */

const hero = {
  heroVideoPlayer: '',
  progress25: false,
  progress50: false,
  progress75: false,
  progress100: false,
  videoCompleted: false,
  onPlayerReady: function (event) {
    event.target.playVideo();
    hero.trackVideoAnalytics();
  },
  // eslint-disable-next-line
  videoIntervals: function (timePercentComplete, videoData, videoPlayProgressTimer) {
    const ev = {};
    const { videoId } = videoData;
    const { videoName } = videoData;

    if (timePercentComplete > 24 && !this.progress25) {
      this.progress25 = true;
      window.digitalData.video = [];
      window.digitalData.video.push({ videoName, videoId });
      ev.eventInfo = {
        type: window.ctConstants.trackEvent,
        eventAction: window.ctConstants.videoProgress,
        eventLabel: `youtube-25-${videoName}-${videoId}`,
        eventValue: 1,
      };
      ev.attributes = { nonInteractive: { nonInteraction: 1 } };
      ev.category = { primaryCategory: window.ctConstants.custom };
      ev.subcategory = 'Read';
      window.digitalData.event.push(ev);
    }

    if (timePercentComplete > 49 && !this.progress50) {
      this.progress50 = true;
      window.digitalData.video = [];
      window.digitalData.video.push({ videoName, videoId });
      ev.eventInfo = {
        type: window.ctConstants.trackEvent,
        eventAction: window.ctConstants.videoProgress,
        eventLabel: `youtube-50-${videoName}-${videoId}`,
        eventValue: 1,
      };
      ev.attributes = { nonInteractive: { nonInteraction: 1 } };
      ev.category = { primaryCategory: window.ctConstants.custom };
      ev.subcategory = 'Read';
      window.digitalData.event.push(ev);
    }

    if (timePercentComplete > 74 && !this.progress75) {
      this.progress75 = true;
      window.digitalData.video = [];
      window.digitalData.video.push({ videoName, videoId });
      ev.eventInfo = {
        type: window.ctConstants.trackEvent,
        eventAction: window.ctConstants.videoProgress,
        eventLabel: `youtube-75-${videoName}-${videoId}`,
        eventValue: 1,
      };
      ev.attributes = { nonInteractive: { nonInteraction: 1 } };
      ev.category = { primaryCategory: window.ctConstants.custom };
      ev.subcategory = 'Read';
      window.digitalData.event.push(ev);
    }

    if (timePercentComplete === 100 && !this.progress100) {
      this.progress100 = true;
      clearInterval(videoPlayProgressTimer);
      window.digitalData.video = [];
      window.digitalData.video.push({ videoName, videoId });
      ev.eventInfo = {
        type: window.ctConstants.trackEvent,
        eventAction: window.ctConstants.videoCompletes,
        eventLabel: `youtube-100-${videoName}-${videoId}`,
        eventValue: 1,
      };
      ev.category = { primaryCategory: window.ctConstants.custom };
      ev.subcategory = 'Interest';
      window.digitalData.event.push(ev);

      this.videoCompleted = true;
    }
  },
  onPlayerStateChange: function () {
    const videoId =
      typeof hero.heroVideoPlayer.getVideoData !== 'undefined'
        ? hero.heroVideoPlayer.getVideoData().video_id
        : '';
    const videoName =
      typeof hero.heroVideoPlayer.getVideoData !== 'undefined'
        ? hero.heroVideoPlayer.getVideoData().title
        : '';

    if (hero.heroVideoPlayer.getPlayerState() === window.YT.PlayerState.PLAYING) {
      const ev = {};
      window.digitalData.video = [];
      window.digitalData.video.push({ videoName, videoId });
      ev.eventInfo = {
        type: window.ctConstants.trackEvent,
        eventAction: window.ctConstants.videoPlays,
        eventLabel: `youtube-0-${videoName}-${videoId}`,
        eventValue: 1,
      };
      ev.category = { primarycategory: window.ctConstants.other };
      ev.subcategory = 'Interest';
      window.digitalData.event.push(ev);
      if (hero.videoCompleted) {
        hero.progress25 = false;
        hero.progress50 = false;
        hero.progress75 = false;
        hero.progress100 = false;
        hero.trackVideoAnalytics();
      }
    }
  },
  trackVideoAnalytics: function () {
    const videoId =
      typeof hero.heroVideoPlayer.getVideoData !== 'undefined'
        ? hero.heroVideoPlayer.getVideoData().video_id
        : '';
    const videoName =
      typeof hero.heroVideoPlayer.getVideoData !== 'undefined'
        ? hero.heroVideoPlayer.getVideoData().title
        : '';
    /* eslint-disable-next-line */
    const videoPlayProgressTimer = setInterval(() => {
      if (hero.heroVideoPlayer.getPlayerState() !== window.YT.PlayerState.PAUSED) {
        const timePercentComplete = Math.round(
          (hero.heroVideoPlayer.getCurrentTime() / hero.heroVideoPlayer.getDuration()) * 100
        );

        const videoData = { videoId, videoName };

        hero.videoIntervals(timePercentComplete, videoData, videoPlayProgressTimer);
      }
    }, 500);
  },
  loadVideo: function (videoContainer, vid, frameheight, framewidth) {
    const videoPlayerOptions = {
      height: frameheight || 600,
      width: '100%',
      videoId: vid,
      host: 'https://www.youtube-nocookie.com',
      playerVars: {
        autoplay: 1,
        enablejsapi: 1,
        origin: window.location.origin,
        widget_referrer: window.location.origin,
      },
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange,
      },
    };

    if (window.YT.player) {
      this.heroVideoPlayer = new window.YT.Player(videoContainer, videoPlayerOptions);
    } else {
      window.onYouTubeIframeAPIReady = () => {
        this.heroVideoPlayer = new window.YT.Player(videoContainer, videoPlayerOptions);
      };
      window.onYouTubeIframeAPIReady();
    }
  },
  attachYoutubescript: function (videoContainerEl, height, width, videoId) {
    var self = this;
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    tag.onload = function () {
      const checkYT = setInterval(() => {
        if (window.YT) {
          self.loadVideo(videoContainerEl, videoId, height, width);
          clearInterval(checkYT);
        }
      }, 999);
    };
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    return true;
  },
  initializeVideoPlayer: function (videoContainerEl, height, width, videoId) {
    this.attachYoutubescript(videoContainerEl, height, width, videoId);
  },
  videoPlayer: function (event) {
    const videoContainerEl = document.querySelector('.c-hero-wrapper');
    if (videoContainerEl) {
      let frameHeight;
      let frameWidth;
      const previewImageElement = document.querySelector('.c-heroImage picture img');
      if (previewImageElement) {
        frameHeight = previewImageElement.clientHeight;
        frameWidth = previewImageElement.clientWidth;
      }
      videoContainerEl.addEventListener('click', (e) => {
        const videoId = e.target.getAttribute('data-videoid');
        if (videoId) {
          this.initializeVideoPlayer(videoContainerEl, frameHeight, frameWidth, videoId);
        }
      });
    }
  },
};

(() => {
  const findMoreBtn = '.find-out-more';
  hero.videoPlayer();
  if (document.querySelector(findMoreBtn)) {
    window.isElementVisible(findMoreBtn);
    window.onScrollOnboarding(findMoreBtn);
  }
  if (document.querySelector('.btn-find-more')) {
    window.onClickFindMoreOnboard();
  }
})();

module.exports = hero;
