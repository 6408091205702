/* eslint-disable no-console */

// Log event in development
export const logEvent = (actionType, analyticsEvent) => {
  const tag = document.querySelector('[data-env]');
  const env = tag.getAttribute('data-env');
  if (env !== 'production') {
    const eventData = JSON.stringify(analyticsEvent);
    const digitalData = JSON.stringify(window.digitalData);
    console.log(actionType, JSON.parse(eventData), JSON.parse(digitalData));
  }
};
