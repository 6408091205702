import { logEvent } from './log-event';

// Clicks to Social Platforms
export const event22 = (e) => {
  const link = e.target.closest('[data-analytics-event22]');

  if (!link) return;

  const text = link.getAttribute('aria-label')
    ? link.getAttribute('aria-label')
    : link.textContent.trim();

  if (!text) return;

  const analyticsEvent = {};
  analyticsEvent.eventInfo = {
    type: window.ctConstants.trackEvent,
    eventAction: window.ctConstants.quickPoll,
    eventLabel: `${text}`,
    eventValue: 1,
  };
  analyticsEvent.category = {
    primaryCategory: window.ctConstants.custom,
  };
  analyticsEvent.subcategory = 'Interest';

  window.digitalData.event.push(analyticsEvent);

  logEvent('Quick Poll Response', analyticsEvent);
};
