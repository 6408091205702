(() => {
  const modalCLose = document.querySelector('#modalCLose');
  const search = document.querySelector('#globalSearch');
  const body = document.querySelector('body');
  const searchInput = document.querySelector('#searchTerm');
  const resetBtn = document.querySelector('#reset');

  modalCLose.addEventListener('click', () => {
    !body.classList.contains('modal--open')
      ? body.classList.add('modal--open')
      : body.classList.remove('modal--open');
    searchInput.value = '';
    searchInput.focus();
  });

  resetBtn.addEventListener('click', () => {
    resetBtn.classList.remove('visible');
    searchInput.value = '';
  });

  searchInput.addEventListener('keyup', () => {
    searchInput.value === ''
      ? resetBtn.classList.remove('visible')
      : resetBtn.classList.add('visible');
  });

  search.addEventListener('submit', (e) => {
    e.preventDefault();

    const searchTerm = document.getElementById('searchTerm').value;
    let searchUrl = document.getElementById('searchUrl').value;
    if (searchTerm !== '') {
      searchUrl = searchUrl.replace('{{searchTerm}}', searchTerm);
      searchInput.value = '';
      window.open(searchUrl, '_self');
    }
  });
})();
