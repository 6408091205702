/* eslint-disable func-names */
/* eslint-disable complexity */

const questions = {
  resText: '',
  checkResults: (totalScore, resultValue) => {
    let resultText = '';
    resultValue.forEach((result) => {
      if (totalScore >= result.minScore && totalScore <= result.maxScore) {
        resultText = result.description;
      }
    });
    if (
      document.querySelector('.c-quiz__result') &&
      document.querySelector('.c-quiz__result').classList.contains('hidden')
    ) {
      document.querySelector('.c-quiz__result').classList.remove('hidden');
    }
    if (document.querySelector('.result-score')) {
      document.querySelector('.result-score').innerHTML = totalScore;
    }
    if (document.querySelector('.result-content')) {
      document.querySelector('.result-content').innerHTML = resultText;
    }

    window.digitalData.component = [];
    window.digitalData.component.push({
      attributes: {
        DTResult: resultText,
      },
    });
  },
  submitQuiz: () => {
    const questionsOptions = [].slice.call(
      document.querySelectorAll('.article-quiz input:checked')
    );
    const quiztype = document.getElementById('quiztype').value;
    const resultEl = document.getElementById('resultText');

    let resultValue = '';
    if (resultEl) {
      this.resText = JSON.parse(resultEl.value);
      resultValue = JSON.parse(resultEl.value);
      resultEl.remove();
    } else {
      resultValue = this.resText;
    }
    let totalScore = 0;
    if (quiztype === 'sumupTheScore') {
      if (questionsOptions.length) {
        questionsOptions.forEach((option) => {
          const optionScore = option.value;
          totalScore += parseInt(optionScore, 10);
        });
      } else {
        totalScore = questionsOptions.length;
      }
      if (resultValue.length) {
        questions.checkResults(totalScore, resultValue);
      }
    } else if (quiztype === 'personaQuiz') {
      questions.calculatePersonaQuizResult(questionsOptions, resultValue);
    }

    // scroll to result section on desktop
    const quizwindowmatch = window.matchMedia('(min-width: 62em)');
    if (quizwindowmatch.matches === true) {
      const scrollEl = document.querySelector('#quiz-result');
      scrollEl.scrollIntoView({ behavior: 'smooth' });
    }
  },
  calculatePersonaQuizResult: (questionsOptions, resultValue) => {
    const optionGroup = {};
    let maxValueKey = '';
    let result = '';
    const quizNotAttempt = document.getElementById('quizNotAttempt')
      ? document.getElementById('quizNotAttempt').value
      : '';
    if (questionsOptions.length) {
      questionsOptions.forEach((option) => {
        const responseId = option.closest('[data-responseId]').getAttribute('data-responseId');
        if (responseId in optionGroup) {
          optionGroup[responseId] += 1;
        } else {
          optionGroup[responseId] = 1;
        }
      });

      const optionGroupSorted = Object.keys(optionGroup)
        .sort()
        .reduce(function (res, key) {
          res[key] = optionGroup[key];
          return res;
        }, {});

      maxValueKey = Object.keys(optionGroupSorted).reduce(function (a, b) {
        return optionGroupSorted[a] >= optionGroupSorted[b] ? a : b;
      });
      const resultData = resultValue.find((res) => res.ResponseId === maxValueKey);
      result = resultData.description;
    } else {
      result = quizNotAttempt;
    }

    if (
      document.querySelector('.c-quiz__result') &&
      document.querySelector('.c-quiz__result').classList.contains('hidden')
    ) {
      document.querySelector('.c-quiz__result').classList.remove('hidden');
    }
    if (document.querySelector('.result-content')) {
      document.querySelector('.result-content').innerHTML = result;
    }

    window.digitalData.component = [];
    window.digitalData.component.push({
      attributes: {
        DTResult: result,
      },
    });
  },
  singleQuestion: (singleQuestions) => {
    const quiztype = document.getElementById('quiztype')
      ? document.getElementById('quiztype').value
      : '';
    singleQuestions.forEach((question) => {
      const options = [].slice.call(question.querySelectorAll('.article-quiz .c-input'));
      options.forEach((input) => {
        input.addEventListener('change', () => {
          const description = input
            .closest('.c-questions__section')
            .querySelector('.c-description');

          if (quiztype !== 'personaQuiz') {
            if (description) {
              description.classList.remove('hidden');
            }

            if (description && description.querySelector('.c-description__info')) {
              description.querySelector('.c-description__info').textContent = input.getAttribute(
                'data-info'
              );
            }
          }
        });
      });
    });
  },
  watchFocus: () => {
    const options = [].slice.call(document.querySelectorAll('.article-quiz .c-input'));

    options.forEach((input) => {
      ['focus', 'focusout'].forEach((event) => {
        input.addEventListener(event, (evt) => {
          // eslint-disable-next-line no-console
          const divToFocus = input.parentElement.querySelector('.state');
          if (evt.type === 'focus') {
            divToFocus.classList.add('focus');
          } else {
            divToFocus.classList.remove('focus');
          }
        });
      });
    });
  },
};

(() => {
  const singleQuestions = [].slice.call(
    document.querySelectorAll('.article-quiz .qtype-singlechoice ')
  );
  if (singleQuestions.length) {
    questions.singleQuestion(singleQuestions);
  }
  questions.watchFocus();
  const articleQuizSubmitBtn = document.querySelector('#submitQuiz');
  if (articleQuizSubmitBtn) {
    articleQuizSubmitBtn.addEventListener('click', questions.submitQuiz);
  }
})();

module.exports = questions;
