(() => {
  const navItem = document.querySelector('.navbar__list');
  const hamburger = document.querySelector('#hamburger');
  const search = document.querySelector('#searchicon');
  const body = document.querySelector('body');

  navItem.addEventListener('click', (e) => {
    !e.target.classList.contains('dropdown--open')
      ? e.target.classList.add('dropdown--open')
      : e.target.classList.remove('dropdown--open');
  });

  hamburger.addEventListener('click', () => {
    !body.classList.contains('menu--open')
      ? body.classList.add('menu--open')
      : body.classList.remove('menu--open');
  });

  search.addEventListener('click', () => {
    const searchInput = document.querySelector('#searchTerm');
    !body.classList.contains('modal--open')
      ? body.classList.add('modal--open')
      : body.classList.remove('modal--open');
    searchInput.value = '';
    searchInput.focus();
  });
})();
