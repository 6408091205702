/* eslint-disable */
import { logEvent } from './log-event';

// Quick poll submit
export const event23 = (e) => {
  if (e.target.hasAttribute('[data-analytics-event23]')) {
    const link = e.target;

    if (!link) return;
    const toolName = link.parentNode.getAttribute('data-tool');

    const text = link.getAttribute('aria-label')
      ? link.getAttribute('aria-label')
      : link.textContent.trim();

    if (!text) return;

    const analyticsEvent = {};
    analyticsEvent.eventInfo = {
      type: window.ctConstants.trackEvent,
      eventAction: window.ctConstants.quickPollSubmit,
      eventLabel: `${toolName}-Submit - ${text}`,
      eventValue: 1,
    };
    analyticsEvent.category = {
      primaryCategory: window.ctConstants.custom,
    };
    analyticsEvent.subcategory = 'Win';

    window.digitalData.event.push(analyticsEvent);

    logEvent('Quick Poll Submit', analyticsEvent);
  }
};
