/* eslint-disable func-names */

const explorepassionNav = {
  passionNav: [].slice.call(document.querySelectorAll('.c-explore-passion-nav li a')),
  resetNavHighlight: function () {
    const activeItems = this.passionNav;
    for (let k = 0; k < activeItems.length; k++) {
      activeItems[k].classList.remove('active');
    }
  },
  highLightNav: function () {
    const items = document.querySelector('.explore-passion-container').children;
    for (let i = 0; i < items.length; i++) {
      const itemRect = items[i].offsetTop - window.pageYOffset;
      if (itemRect > -500 && itemRect < 200) {
        this.resetNavHighlight();
        const itemId = `.c-explore-passion-nav li a[href="#${items[i].id}"]`;
        document.querySelector(itemId).classList.add('active');
      }
    }
  },
  onEditorial: function () {
    const editorialBlock = document.querySelector('.editorial-block');
    if (editorialBlock.offsetTop - window.pageYOffset < 100) {
      this.resetNavHighlight();
    }
  },
  onPageScroll: function (watchElement, navWrapper) {
    const offsetval = watchElement.offsetTop + watchElement.offsetHeight - window.pageYOffset;
    if (offsetval <= 0) {
      navWrapper.classList.add('sticky-mode');
      this.highLightNav();
      this.onEditorial();
    } else {
      navWrapper.classList.remove('sticky-mode');
    }
  },
  bindScrollEvent: function () {
    const watchElement = document.querySelector('.c-explore-passion-nav');
    const navWrapper = document.querySelector('.passion-nav__block');
    document.addEventListener('scroll', () => {
      this.onPageScroll(watchElement, navWrapper);
    });
  },
  passionPageScroll: function (e) {
    const elId = e.currentTarget.hash;
    const focusEl = document.getElementById(elId.slice(1));
    const elementPosition = focusEl.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset;
    if (window.innerWidth >= 992) {
      offsetPosition += 60;
    }
    const obj = {};
    obj.behavior = 'smooth';
    obj.top = offsetPosition;
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo(obj);
    } else {
      window.scroll(0, offsetPosition);
    }
  },
  bindClickEvent: function () {
    for (let i = 0; i < this.passionNav.length; i++) {
      this.passionNav[i].addEventListener('click', (e) => {
        e.preventDefault();
        this.passionPageScroll(e);
      });
    }
  },
};

(() => {
  if (explorepassionNav.passionNav && explorepassionNav.passionNav.length) {
    explorepassionNav.bindScrollEvent();
    explorepassionNav.bindClickEvent();
  }
})();
