/* eslint-disable */
(() => {
  const select = document.querySelectorAll('.c-select');
  if (select && select.length && select.length > 0) {
    for (let i = 0; i < select.length; i++) {
      const elt = select[i];
      const selectId = elt.getAttribute('id');
      let filterId = document.getElementById(selectId);
      filterId.addEventListener('click', (event) => {
        event.stopPropagation();
        !filterId.classList.contains('active')
          ? filterId.classList.add('active')
          : filterId.classList.remove('active');
        if (event.target.tagName === 'LI') {
          const selectedValue = event.target.getAttribute('data-input-value');
          filterId.children[0].innerText = event.target.innerText;
          filterId.children[0].setAttribute('data-input-value', selectedValue);
          filterId.querySelector('.selectedValue').value = selectedValue;
          const eve = new Event('change');
          filterId.querySelector('.selectedValue').dispatchEvent(eve);
        }
      });

      document.addEventListener('click', () => {
        if (filterId) {
          filterId.classList.remove('active');
        }
      });
    }
  }
})();
