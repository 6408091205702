(() => {
  const navbar = document.getElementById('stickyNav');
  if (navbar) {
    const navOffset = navbar.offsetTop;
    const stickyNav = () => {
      if (navbar) {
        window.pageYOffset >= navOffset
          ? navbar.classList.add('sticky')
          : navbar.classList.remove('sticky');
      }
    };

    window.onscroll = () => stickyNav();
  }
})();
