/* eslint-disable */
const recentChallenge = () => {
  const articles = JSON.parse(window.storage().getStorage('readarticles'));
  const recentChallenges = document.querySelector('.c-recentChallenge__list');
  const featuredChallenge = document.querySelector('.c-featured-challenge');
  const button = document.getElementById('loadRecentChallenge');
  const startIndexChallenge = document.querySelector('#startIndex__challenge');
  const articleType = document.querySelector('#articleType__challenge');

  if (recentChallenges || featuredChallenge) {
    if (articles && articles.length > 0) {
      featuredChallenge.classList.toggle('hidden', true);
      const recent = featuredChallenge;

      const json = `articleids=${articles.reverse()}&articletypes=${
        articleType.value
      }&imgwidth=568&imgheight=287&sortby=inputids&start=${Number(
        startIndexChallenge.value
      )}&end=2`;
      window.getAjaxData(2, json, recentChallenges, 'challenge', true, button, recent);
      startIndexChallenge.value = Number(startIndexChallenge.value) + 2;
    } else {
      featuredChallenge.classList.toggle('hidden', false);
      button.classList.toggle('hidden', true);
    }
  }
  if (button) {
    button.addEventListener('click', (event) => {
      const loadbtn = event.currentTarget;
      let json = '';
      json = `articleids=${articles}&articletypes=${
        articleType.value
      }&imgwidth=568&imgheight=287&sortby=inputids&start=${Number(startIndexChallenge.value)}&end=${
        Number(startIndexChallenge.value) + 1
      }`;
      window.getAjaxData(2, json, recentChallenges, 'challenge', false, loadbtn);
      startIndexChallenge.value = Number(startIndexChallenge.value) + 2;
    });
  }
};
(() => {
  recentChallenge();
})();
