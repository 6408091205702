/* eslint-disable func-names */

const articleListing = {
  loadButton: document.querySelector('#load'),
  startIndex: document.querySelector('#startIndex'),
  endIndex: document.querySelector('#endIndex'),
  passionType: document.querySelector('#passionType'),
  select: document.querySelector('.c-select'),
  articleWrapper: document.querySelector('.articleList__list'),
  loadbtn: document.getElementById('load'),
  motivator: document.getElementById('motivator'),
  loadBtnEvent: function () {
    let json = '';
    this.loadButton.addEventListener('click', () => {
      const articleType = document
        .getElementById('articleType')
        .children[0].getAttribute('data-input-value');
      if (Number(this.startIndex.value) === 1) {
        json = `imgwidth=120&imgheight=160&passion=${this.passionType.value}&start=${Number(
          this.startIndex.value
        )}&end=${Number(this.endIndex.value) * 2}&articletypes=${articleType}`;
        if (this.motivator) {
          json += `&motivator=${this.motivator.value}`;
        }
        window.getAjaxData(
          Number(this.endIndex.value) * 2,
          json,
          this.articleWrapper,
          'article',
          true,
          this.loadbtn
        );
        this.startIndex.value = Number(this.startIndex.value) + Number(this.endIndex.value) * 2;
      } else {
        json = `imgwidth=120&imgheight=160&passion=${this.passionType.value}&start=${Number(
          this.startIndex.value
        )}&end=${
          Number(this.endIndex.value) - 1 + Number(this.startIndex.value)
        }&articletypes=${articleType}`;
        if (this.motivator) {
          json += `&motivator=${this.motivator.value}`;
        }
        window.getAjaxData(
          Number(this.endIndex.value),
          json,
          this.articleWrapper,
          'article',
          false,
          this.loadbtn
        );
        this.startIndex.value = Number(this.endIndex.value) + Number(this.startIndex.value);
      }
    });
  },
  getListing: function () {
    let json = '';
    if (this.articleWrapper) {
      if (this.loadButton) {
        this.loadBtnEvent();
      }
      this.select.addEventListener('click', (event) => {
        event.stopPropagation();
        if (event.target.tagName === 'LI') {
          let endIndexVal = Number(this.endIndex.value);
          if (Number(this.startIndex.value) !== 1) {
            endIndexVal = Number(this.startIndex.value) - 1;
          }
          if (event.target.getAttribute('data-input-value') === '') {
            json = `imgwidth=120&imgheight=160&passion=${this.passionType.value}&start=1&end=${endIndexVal}`;
          } else {
            json = `imgwidth=120&imgheight=160&passion=${this.passionType.value}&start=1&end=${endIndexVal}&articletypes=${event.target.innerText}`;
          }
          if (this.motivator) {
            json += `&motivator=${this.motivator.value}`;
          }
          window.getAjaxData(endIndexVal, json, this.articleWrapper, 'article', true, this.loadbtn);
        }
      });
    }
  },
};

(() => {
  articleListing.getListing();
})();
