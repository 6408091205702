import { logEvent } from './log-event';

// Tool Usage
export const event3 = (e) => {
  const link = e.target.closest('[data-analytics-event3]');

  if (!link) return;

  const text = link.textContent;

  if (!text) return;

  const analyticsEvent = {};
  analyticsEvent.eventInfo = {
    type: window.ctConstants.trackEvent,
    eventAction: window.ctConstants.anchorLinkClicked,
    eventLabel: text,
    eventValue: 1,
  };
  analyticsEvent.category = {
    primaryCategory: window.ctConstants.custom,
  };

  window.digitalData.event.push(analyticsEvent);

  logEvent('Tool Usage', analyticsEvent);
};
