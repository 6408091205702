/* eslint-disable func-names */

const moodSelect = {
  retainMood: function (data, moods, preSelectedMood, articleId) {
    if (window.storage().getStorage('userMood')) {
      data = JSON.parse(window.storage().getStorage('userMood'));
      data.forEach((d) => {
        if (d.article === articleId) {
          preSelectedMood = d.userMood;
        }
      });
      moods.forEach((el) => {
        const smiley = el.getAttribute('aria-label').toLowerCase();
        if (smiley === preSelectedMood) {
          el.classList.add('active');
        }
      });
    }
  },
  getFinalData: function (finalVal, moodData, finalData, articleId) {
    if (window.storage().getStorage('userMood')) {
      moodData = JSON.parse(window.storage().getStorage('userMood'));
      finalData = JSON.parse(window.storage().getStorage('userMood'));
      finalData.filter((d, j) => {
        if (d.article === articleId) {
          finalData.splice(j, 1);
        }
        return null;
      });
      finalData.push(finalVal);

      if (moodData.length < 1) {
        window.storage().setStorage('userMood', `[ ${JSON.stringify(finalVal)} ]`);
      } else {
        window.storage().setStorage('userMood', JSON.stringify(finalData));
      }
    } else {
      window.storage().setStorage('userMood', `[ ${JSON.stringify(finalVal)} ]`);
    }
  },
};

(() => {
  const moods = [].slice.call(document.querySelectorAll('.c-mood-selector_emoji_link'));
  const articlePage = document.querySelector('.article-wrapper');
  if (moods.length && articlePage) {
    const articleId = articlePage.getAttribute('data-page-id');
    let preSelectedMood;
    let data;
    let moodData;
    let finalData;

    // Retain active mood of article from localstorage
    moodSelect.retainMood(data, moods, preSelectedMood, articleId);
    moods.forEach((mood) => {
      // adding click event on mood
      mood.addEventListener('click', (elt) => {
        moods.forEach((el) => {
          el.classList.remove('active');
        });
        !elt.currentTarget.classList.contains('active')
          ? elt.currentTarget.classList.add('active')
          : elt.currentTarget.classList.remove('active');

        // setting localstorage w.r.t. article
        const selectedMood = elt.currentTarget.getAttribute('aria-label').toLowerCase();
        const finalVal = { article: articleId, userMood: selectedMood };

        moodSelect.getFinalData(finalVal, moodData, finalData, articleId);
      });
    });
  }
})();
