function requireAll(r) {
  r.keys().forEach(r);
}
requireAll(require.context('../svg/', true, /\.svg$/));

const xhr = new XMLHttpRequest();
xhr.open('GET', '/assets/sprites/sprite.svg');
/* eslint-disable-next-line func-names */
xhr.onreadystatechange = function () {
  if (this.readyState === 4 && this.status === 200) {
    const data = xhr.responseText;
    document.getElementById('svg-icons').innerHTML = data;
  }
};
/* eslint-disable-next-line func-names */
xhr.onerror = function (e) {
  /* eslint-disable-next-line no-console */
  console.log('Error occurred while fetching results.');
};
xhr.send();

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

// eslint-disable-next-line no-restricted-globals
addEventListener(
  'pagehide',
  (event) => {
    scrollToTop();
  },
  { capture: true }
);
