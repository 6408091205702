import { logEvent } from './log-event';

// Clicks to Social Platforms
export const event47 = (e) => {
  if (e.target.closest('[data-analytics-event47]')) {
    const link = e.target;

    if (!link) return;

    const text = link.getAttribute('aria-label');
    const anchorLink = link.href;

    if (!text || !anchorLink) return;

    const analyticsEvent = {};
    analyticsEvent.eventInfo = {
      type: window.ctConstants.trackEvent,
      eventAction: window.ctConstants.share,
      eventLabel: `${text}`,
      eventValue: 1,
    };
    analyticsEvent.category = {
      primaryCategory: window.ctConstants.referral,
    };
    analyticsEvent.subcategory = 'Lead';
    window.digitalData.event.push(analyticsEvent);

    logEvent('Click to Social Share', analyticsEvent);
  }
};
