/* eslint-disable */
const recentlyRead = {
  recentArticles: function () {
    const articles = JSON.parse(window.storage().getStorage('readarticles'));
    const readArticles = document.querySelector('.c-recentlyRead__list');
    const articlesWrapper = document.querySelector('.c-recentlyRead-wrapper');
    const noArticle = document.querySelector('.c-no-articles');
    const recentlyReadFilter = document.getElementById('recentlyReadFilter');
    const button = document.getElementById('loadRecentRead');
    const startreadIndex = document.querySelector('#startIndex__read');
    const endIndex = document.querySelector('#endIndex__read');
    const select = document.getElementById('recentlyReadFilter');
    let articleTypes = [];
    if (select) {
      const articleTypesLI = [].slice.call(select.querySelectorAll('li'));

      articleTypesLI.forEach((articleType) => {
        if (articleType.dataset.value != '') articleTypes.push(articleType.dataset.value);
      });
    }

    if (readArticles || noArticle) {
      if (articles && articles.length > 0) {
        articlesWrapper.classList.toggle('hidden', false);
        const recent = articlesWrapper;
        const articlesIds = `articleids=${articles.reverse()}&articletypes=${articleTypes}&sortby=inputids&start=${Number(
          startreadIndex.value
        )}&end=${Number(endIndex.value)}`;
        window.getAjaxData(
          Number(endIndex.value),
          articlesIds,
          readArticles,
          'articles',
          true,
          button,
          recent
        );
        startreadIndex.value = Number(startreadIndex.value) + Number(endIndex.value);

        recentlyReadFilter.addEventListener('click', (event) => {
          recentlyRead.changeFilter(
            event,
            articles,
            readArticles,
            button,
            startreadIndex,
            endIndex
          );
        });
        button.addEventListener('click', (event) => {
          recentlyRead.loadMore(event, articles, readArticles, startreadIndex, endIndex);
        });
      } else {
        articlesWrapper.classList.toggle('hidden', true);
        noArticle.classList.toggle('hidden', false);
        button.classList.toggle('hidden', true);
      }
    }
  },
  changeFilter: function (event, articles, readArticles, button, startreadIndex, endIndex) {
    event.stopPropagation();
    const select = document.getElementById('recentlyReadFilter');
    const articleTypesLI = [].slice.call(select.querySelectorAll('li'));
    let articleTypes = [];

    articleTypesLI.forEach((articleType) => {
      if (articleType.dataset.value != '') articleTypes.push(articleType.dataset.value);
    });
    let json;
    if (event.target.tagName === 'LI') {
      if (event.target.getAttribute('data-input-value') === '') {
        json = `articleids=${articles}&imgwidth=120&imgheight=160&articletypes=${articleTypes}&sortby=inputids&start=1&end=${Number(
          endIndex.value
        )}`;
      } else {
        json = `articleids=${articles}&imgwidth=120&imgheight=160&articletypes=${
          event.target.innerText
        }&sortby=inputids&start=1&end=${Number(endIndex.value)}`;
      }
      window.getAjaxData(Number(endIndex.value), json, readArticles, 'article', true, button);
      startreadIndex.value = 1 + Number(endIndex.value);
    }
  },
  loadMore: function (event, articles, readArticles, startreadIndex, endIndex) {
    const loadbtn = event.currentTarget;
    const select = document.getElementById('recentlyReadFilter');
    const selectedValue = document.querySelector('#recentlyReadFilter .selectedValue').value;
    let articleTypes = [];
    if (selectedValue === '') {
      const articleTypesLI = [].slice.call(select.querySelectorAll('li'));
      articleTypesLI.forEach((articleType) => {
        if (articleType.dataset.value != '') articleTypes.push(articleType.dataset.value);
      });
    } else {
      articleTypes = selectedValue;
    }
    let json = '';
    json = `articleids=${articles}&articletypes=${articleTypes}&imgwidth=120&imgheight=160&sortby=inputids&start=${Number(
      startreadIndex.value
    )}&end=${Number(startreadIndex.value) + (Number(endIndex.value) - 1)}`;
    window.getAjaxData(Number(endIndex.value), json, readArticles, 'article', false, loadbtn);

    startreadIndex.value = Number(startreadIndex.value) + Number(endIndex.value);
  },
};
(() => {
  recentlyRead.recentArticles();
})();
