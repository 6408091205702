import { logEvent } from './log-event';

// Article Click
export const event4 = (e) => {
  const link = e.target.closest('.articleBlock__link');

  if (!link) return;

  let text = '';
  if (link.getAttribute('title').trim() !== '') {
    text = link.getAttribute('title').trim();
  } else if (link.getAttribute('aria-label').trim() !== '') {
    text = link.getAttribute('aria-label').trim();
  } else if (link.textContent.trim() !== '') {
    link.textContent.trim();
  }

  if (!text) return;

  const analyticsEvent = {};
  analyticsEvent.eventInfo = {
    type: window.ctConstants.trackEvent,
    eventAction: window.ctConstants.articleClick,
    eventLabel: `${text}`,
    eventValue: 1,
  };
  analyticsEvent.category = {
    primaryCategory: window.ctConstants.other,
  };
  analyticsEvent.subcategory = 'Read';

  window.digitalData.event.push(analyticsEvent);

  logEvent('Article Click', analyticsEvent);
};
