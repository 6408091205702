/* eslint-disable */
const profileFuncs = {
  init: function () {
    const profiler = document.querySelector('.c-profiler');
    if (profiler) {
      const accordion = document.querySelector('.c-accordion');
      const profilerContent = document.querySelector('.profiler__content');
      const profilerResult = document.querySelector('.profiler__result');
      const quiz = document.querySelector('#profilerQuiz');
      const checkboxes = [].slice.call(document.querySelectorAll('#profilerQuiz .c-input'));
      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', (event) => {
          if (this.isvalidate()) {
            document.getElementById('submitAnswer').disabled = false;
          } else {
            document.getElementById('submitAnswer').disabled = true;
          }
        });

        ['focus', 'focusout'].forEach((event) => {
          checkbox.addEventListener(event, (evt) => {
            // eslint-disable-next-line no-console
            const divToFocus = checkbox.parentElement.querySelector('.state');
            if (evt.type === 'focus') {
              divToFocus.classList.add('focus');
            } else {
              divToFocus.classList.remove('focus');
            }
          });
        });
      });

      if (window.storage() && window.storage().getStorage('profiler')) {
        accordion.classList.add('c-accordion-active');
        accordion.classList.add('collapsed');
        profilerContent.classList.add('collapsed');
        profilerResult.classList.remove('collapsed');
        document.getElementById('submitAnswer').disabled = false;
        this.recommendArticles();
        this.populatequiz();
        this.accordionInit();
      }

      quiz.addEventListener('submit', (event) => {
        event.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        // filter dropdown reset
        const select = document.querySelectorAll('.c-select');
        if (select && select.length && select.length > 0) {
          for (let i = 0; i < select.length; i++) {
            const elt = select[i];
            const selectId = elt.getAttribute('id');
            let filterId = document.getElementById(selectId);
            const defaultPlaceholder = filterId.getElementsByClassName('dropdown')[0].children[0]
              .innerText;
            filterId.children[0].innerText = defaultPlaceholder;
            filterId.children[0].setAttribute('data-input-value', '');
            filterId.querySelector('.selectedValue').value = '';
            const eve = new Event('change');
            filterId.querySelector('.selectedValue').dispatchEvent(eve);
          }
        }
        this.calculateResult();
        this.recommendArticles();
      });
    }
  },
  isvalidate: function () {
    let valid = true;
    const inputsWrapper = [].slice.call(
      document.querySelectorAll('#profilerQuiz .options_wrapper')
    );
    inputsWrapper.forEach((input) => {
      if (input.classList.contains('qtype-singlechoice')) {
        const inputs = input.querySelectorAll('input:checked');
        if (inputs.length === 0) {
          valid = false;
        }
      } else {
        const inputs = input.querySelectorAll('input:checked');
        if (inputs.length < 2) {
          valid = false;
        }
      }
    });
    return valid;
  },

  setOp: function (op, tag) {
    op.set(tag, (op.get(tag) || 0) + 1);
    return op;
  },

  calculateResult: function () {
    const questionsOptions = [].slice.call(
      document.querySelectorAll('#profilerQuiz input:checked')
    );
    const resultOutput = document.querySelector('.result-content');
    const accordion = document.querySelector('.c-accordion');
    const profilerContent = document.querySelector('.profiler__content');
    const profilerResult = document.querySelector('.profiler__result');
    const resultData = document.querySelector('#resultdata');
    const startIndex = document.querySelector('#startIndex');

    // logic for profiler quiz
    if (questionsOptions.length) {
      const optionsValue = {};
      questionsOptions.forEach((option) => {
        const responseGroup = option
          .closest('[data-responseGroup]')
          .getAttribute('data-responseGroup');

        const optionfield = {
          answerID: option.id,
          tag: option.value,
        };
        /* for localstorage */
        if (responseGroup in optionsValue) {
          optionsValue[responseGroup].push(optionfield);
        } else {
          optionsValue[responseGroup] = [];
          optionsValue[responseGroup].push(optionfield);
        }
      });

      const { motivator } = optionsValue;
      // get highest motivator
      const selectedTag = [
        ...motivator
          .reduce((op, inp) => {
            const { tag } = inp;
            return this.setOp(op, tag);
          }, new Map())
          .entries(),
      ]
        .filter(([_, repeat]) => repeat > 1)
        .map(([tag, repeat]) => ({
          tag,
          repeat,
        }));
      const result = JSON.parse(resultData.value);
      if (selectedTag.length > 0) {
        result.forEach((option) => {
          if (option.resultTag.title === selectedTag[0].tag) {
            optionsValue.result = {};
            optionsValue.result = {
              displayText: option.richText,
              tag: selectedTag[0].tag,
            };
            resultOutput.innerHTML = option.richText;

            this.analyticsEvent25(option.richText);
          }
        });
      } else {
        optionsValue.result = {};
        optionsValue.result = {
          displayText: result[0].richText,
          tag: result[0].resultTag.title,
        };
        resultOutput.innerHTML = result[0].richText;

        this.analyticsEvent25(result[0].richText);
      }
      startIndex.value = 1;
      window.storage().setStorage('profiler', JSON.stringify([optionsValue]));

      if (accordion.classList.contains('c-accordion-active')) {
        accordion.classList.add('collapsed');
        profilerContent.classList.add('collapsed');
        profilerResult.classList.remove('collapsed');
      } else {
        accordion.classList.add('c-accordion-active');
        accordion.classList.add('collapsed');
        profilerContent.classList.add('collapsed');
        profilerResult.classList.remove('collapsed');
        this.accordionInit();
      }
    }
  },

  recommendArticles: function () {
    const startIndex = document.querySelector('#startIndex');
    const endIndex = document.querySelector('#endIndex');
    const passionType = document.querySelector('#passionType');
    const articleType = document
      .getElementById('articleType')
      .children[0].getAttribute('data-input-value');
    const articleWrapper = document.querySelector('.articleList__list');
    const button = document.getElementById('load');
    articleWrapper.innerHTML = '';

    if (window.storage() && window.storage().getStorage('profiler')) {
      const wrapper = document.querySelector('.tab-wrapper');
      const storageData = JSON.parse(window.storage().getStorage('profiler'));
      const exploreTag = storageData[0]['result']['tag'];
      document.getElementById('motivator').value = exploreTag;
      let passionsTags = [
        ...new Set(
          storageData[0]['passion'].map((elem) => {
            return elem.tag;
          })
        ),
      ].join(',');
      passionType.value = passionsTags;
      let json = `imgwidth=120&imgheight=160&passion=${passionType.value}&start=${Number(
        startIndex.value
      )}&end=${Number(endIndex.value)}&articletypes=${articleType}&motivator=${exploreTag}`;

      window.getAjaxData(Number(endIndex.value), json, articleWrapper, 'article', true, button);
      startIndex.value = Number(startIndex.value) + Number(endIndex.value);
      wrapper.classList.remove('hidden');
    }
  },

  populatequiz: function () {
    if (window.storage() && window.storage().getStorage('profiler')) {
      const resultOutput = document.querySelector('.result-content');
      const storageData = JSON.parse(window.storage().getStorage('profiler'));
      /* eslint-disable no-restricted-syntax */
      for (const [key, value] of Object.entries(storageData[0])) {
        if (key === 'result') {
          resultOutput.innerHTML = value.displayText;
        } else {
          value.forEach((option) => {
            document.getElementById(option.answerID).checked = 'checked';
          });
        }
      }
    }
  },

  accordionInit: function () {
    const accordionActive = document.querySelector('.c-accordion-active');
    const profilerContent = document.querySelector('.profiler__content');
    const profilerResult = document.querySelector('.profiler__result');
    accordionActive.addEventListener('click', (e) => {
      if (profilerContent.classList.contains('collapsed')) {
        accordionActive.classList.remove('collapsed');
        profilerContent.classList.remove('collapsed');
        profilerResult.classList.add('collapsed');
      } else {
        accordionActive.classList.add('collapsed');
        profilerContent.classList.add('collapsed');
        profilerResult.classList.remove('collapsed');
      }
    });
  },
  analyticsEvent25: function (result) {
    const nodes = Array.prototype.slice.call(document.querySelectorAll('.options_wrapper'));
    const total = nodes.length;
    const analyticsEvent = {};
    if (window.digitalData.component > 0) {
      window.digitalData.component[0].attributes.position = `${total} of ${total}`;
      window.digitalData.component[0].attributes.DTResult = result;
      window.digitalData.component[0].attributes.subattributes = [];
    } else {
      window.digitalData.component = [];
      window.digitalData.component.push({
        attributes: {
          position: `${total} of ${total}`,
          DTResult: result,
          subattributes: [],
        },
      });
    }

    analyticsEvent.eventInfo = {
      type: window.ctConstants.diagtooleve,
    };
    analyticsEvent.category = {
      primaryCategory: window.ctConstants.engagement,
    };
    analyticsEvent.subcategory = 'Interest';
    analyticsEvent.action = window.ctConstants.DiagnosticToolResults;
    analyticsEvent.label = 'result';

    window.digitalData.event.push(analyticsEvent);
  },
};

module.exports = profileFuncs;

(() => {
  profileFuncs.init();
})();
