const tabView = {
  // eslint-disable-next-line func-names
  toggleTabs: function () {
    const tabGroup = document.querySelector('.profiler-tabs');
    const tabs = [].slice.call(tabGroup.querySelectorAll('.tab'));

    tabs.forEach((element) => {
      element.addEventListener(
        'click',
        (event) => {
          const index = event.target.getAttribute('data-index');
          [].slice.call(document.querySelectorAll('.tab-panel .tabcontent')).forEach((elm) => {
            elm.classList.remove('active');
          });
          document.querySelectorAll('.tab-panel .tabcontent')[index].classList.add('active');

          tabs.forEach((elm) => {
            elm.classList.remove('active');
          });

          event.target.classList.add('active');
        },
        false
      );
    });
  },
};

(() => {
  if (document.querySelector('.profiler-tabs')) {
    tabView.toggleTabs();
  }
})();
