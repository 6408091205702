import { logEvent } from './log-event';
/* eslint-disable no-console */
// Link Click
export const event2 = (e) => {
  const link = e.target.closest('a');
  // not an 'a', missing 'href' or is a hash/anchor ?
  // || link.href.indexOf('#') !== -1
  if (!link || (link && !link.href)) return;
  if (
    link.getAttribute('data-analytics-event47') !== null ||
    link.getAttribute('data-analytics-event48') !== null
  ) {
    return;
  }

  const text = link.textContent === '' ? link.getAttribute('aria-label') : link.textContent.trim();

  const analyticsEvent = {};
  analyticsEvent.eventInfo = {
    type: window.ctConstants.trackEvent,
    eventAction: window.ctConstants.linkClick,
    eventLabel: `${text} - ${link.href}`,
    eventValue: 1,
  };
  analyticsEvent.category = {
    primaryCategory: window.ctConstants.custom,
  };

  window.digitalData.event.push(analyticsEvent);

  logEvent('Link Click', analyticsEvent);
};
