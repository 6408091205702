import Swiper from 'swiper/bundle';

const enableSwiper = () => {
  new Swiper('.imageCarousel', {
    loop: true,
    lazy: true,
    slidesPerView: 'auto',
    centeredSlides: false,
    autoplay: false,
    accessibility: false,
    direction: 'horizontal',
    observeParents: true,
    observer: true,

    pagination: {
      el: '.imageCarousel__pagination',
      clickable: false,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
      hideOnClick: true,
    },
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    on: {
      init: () => {},
    },
    observerUpdate: {
      init: () => {},
    },
  });
};

const handleTabbing = () => {
  if (document.querySelector('.imageCarousel')) {
    const slides = document.querySelectorAll('.imageCarousel .swiper-slide .is-link');
    slides.forEach((slide) => {
      slide.setAttribute('tabindex', '-1');
    });
    document.querySelector('.swiper-slide-active .is-link').setAttribute('tabindex', '0');
  }
};

enableSwiper();
handleTabbing();

[
  document.querySelector('.imageCarousel .swiper-button-prev'),
  document.querySelector('.imageCarousel .swiper-button-next'),
].forEach((item) => {
  if (item) {
    item.addEventListener('click', () => {
      handleTabbing();
    });

    item.addEventListener('keydown', (evt) => {
      if (evt.keyCode === 13) {
        item.click();
      }
    });
  }
});
