import { logEvent } from './log-event';

// Clicks to Social Platforms
export const event49 = (e) => {
  const link = e.target.closest('[data-analytics-event49]');

  if (!link) return;

  const text = link.textContent === '' ? link.getAttribute('aria-label') : link.textContent.trim();
  const anchorLink = link.href;

  if (!text || !anchorLink) return;

  const analyticsEvent = {};
  analyticsEvent.eventInfo = {
    type: window.ctConstants.trackEvent,
    eventAction: window.ctConstants.downloads,
    eventLabel: `${anchorLink}`,
    eventValue: 1,
  };
  analyticsEvent.category = {
    primaryCategory: window.ctConstants.other,
  };
  analyticsEvent.subcategory = 'Interest';

  window.digitalData.event.push(analyticsEvent);

  logEvent('Click to Social Platform', analyticsEvent);
};
