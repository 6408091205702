import { logEvent } from './log-event';

// Clicks to Social Platforms
export const event48 = (e) => {
  const link = e.target.closest('[data-analytics-event48]');

  if (!link) return;

  const text = link.textContent === '' ? link.getAttribute('aria-label') : link.textContent.trim();
  const anchorLink = link.href;

  if (!text || !anchorLink) return;

  const analyticsEvent = {};
  analyticsEvent.eventInfo = {
    type: window.ctConstants.trackEvent,
    eventAction: window.ctConstants.clickstosocialplatforms,
    eventLabel: `${text} - ${anchorLink}`,
    eventValue: 1,
  };
  analyticsEvent.category = {
    primaryCategory: window.ctConstants.custom,
  };

  window.digitalData.event.push(analyticsEvent);

  logEvent('Click to Social Platform', analyticsEvent);
};
