// eslint-disable-next-line
require('intersection-observer');

const progimage = {
  // eslint-disable-next-line
  isIE: function () {
    return !!(navigator.userAgent.match(/Trident/) && !navigator.userAgent.match(/MSIE/));
  },
  // eslint-disable-next-line
  lazyLoadIE: function () {
    let performchange = true;
    const unlazyElements = [].slice.call(document.querySelectorAll('picture.lazyload-false'));
    if (unlazyElements.length && performchange) {
      let eleCnt = 0;
      unlazyElements.forEach((picture) => {
        const imageTag = picture.querySelector('img');
        imageTag.setAttribute('src', picture.getAttribute('data-imgsrc').split(' 1x')[0]);
        eleCnt += 1;
        if (eleCnt === unlazyElements.length - 1) {
          performchange = false;
        }
      });
    }
  },
};

(() => {
  const isIE = progimage.isIE();

  if (isIE) {
    progimage.lazyLoadIE();
  }

  window.ObserveImages = (elem) => {
    // eslint-disable-next-line
    const imageObserver = new IntersectionObserver((entries, imgObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyPicture = entry.target;
          const pic = [].slice.call(lazyPicture.querySelectorAll('source'));
          const lazyImageImg = lazyPicture.querySelector('img');
          if (isIE) {
            lazyImageImg.setAttribute(
              'src',
              lazyPicture.getAttribute('data-imgsrc').split(' 1x')[0]
            );
          }

          pic.forEach((source) => {
            source.setAttribute('srcset', source.getAttribute('data-srcset'));
          });
          lazyPicture.classList.remove('is-loading');
          lazyPicture.classList.add('is-loaded');
          imageObserver.unobserve(lazyPicture);
        }
      });
    });
    elem.forEach((val) => {
      imageObserver.observe(val);
    });
  };

  const elements = [].slice.call(document.querySelectorAll('picture.lazyload-true'));
  window.ObserveImages(elements);
})();
