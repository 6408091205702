/* eslint-disable func-names */
const socialFuncs = {
  isQuiz: function () {
    const resultCont = document.querySelector('.result-content');
    const isQuiz =
      document.querySelector('.article-quiz') || document.querySelector('.profilerquiz');
    const isQuizAttempted = resultCont && resultCont.textContent.length > 1;

    if (isQuiz && isQuizAttempted) return true;
    return false;
  },
  getResultsText: function () {
    const resultCont = document.querySelector('.result-content');
    const resultHeading = `${document.querySelector('.result-heading').textContent}`;
    const resultDesc = `${resultCont.textContent}`;
    const resultText = `${resultHeading} - ${resultDesc}`;
    return resultText;
  },
};

(() => {
  const breakpoint = window.matchMedia('(min-width: 36em)');
  const whatsapp = document.querySelector('.js-social-share-whatsapp');
  const fb = document.querySelector('.js-social-share-facebook');
  const twitter = document.querySelector('.js-social-share-twitter');
  const page = document.URL;
  const pageTitle = document.title;

  // whatsapp share
  if (whatsapp) {
    whatsapp.addEventListener('click', (e) => {
      let text = socialFuncs.isQuiz()
        ? socialFuncs.getResultsText()
        : document.querySelector("meta[property='og:title']").getAttribute('content');

      text += '%0A';
      if (breakpoint.matches === false) {
        e.currentTarget.href = `http://wa.me/?text=${text + page}`;
      } else if (breakpoint.matches === true) {
        e.currentTarget.href = `https://web.whatsapp.com/send?text=${text + page}`;
      }
      e.currentTarget.setAttribute('target', '_blank');
    });
  }
  // twitter share
  if (twitter) {
    twitter.addEventListener('click', (e) => {
      const text = socialFuncs.isQuiz() ? socialFuncs.getResultsText() : pageTitle;
      e.currentTarget.href = `https://twitter.com/intent/tweet?original_referer=${encodeURIComponent(
        page
      )}&ref_src=twsrc%5Etfw&text=${encodeURIComponent(text)}&url=${encodeURIComponent(page)}`;
      e.currentTarget.setAttribute('target', '_blank');
    });
  }
  // facebook share
  if (fb) {
    fb.addEventListener('click', (e) => {
      const text = socialFuncs.isQuiz() ? socialFuncs.getResultsText() : '';
      e.currentTarget.href = '#';
      const uri = `https://www.facebook.com/sharer/sharer.php?u=${page}&quote=${text}`;
      window.open(uri, 'pop', 'width=350 , height=150, scrollbars=no');
      return false;
    });
  }
})();
