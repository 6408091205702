/* eslint no-unused-vars: 0 */
window.storage = () => {
  return {
    getStorage(item) {
      return localStorage.getItem(item);
    },
    setStorage(item, val) {
      localStorage.setItem(item, val);
    },
    clearStorage(item) {
      localStorage.removeItem(item);
    },
  };
};
